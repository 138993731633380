<template>
  <div class="ibox" ref="workersConfiguration">
    <div class="ibox-title">
      <h4>Workers configuration</h4>
      <div class="ibox-tools">
        <a class="collapse-link">
            <i class="fa fa-chevron-down"></i>
        </a>
      </div>
    </div>
    <div class="ibox-content" style="display:none">
      <form id="config-workers-form" name="config-workers-form" role="form" action="#" method="post" @submit="onFormSubmit">
        <div class="form-group">
            <label for="providerCompany">{{ $t('configuration.workers.providerCompany') }}</label>
            <v-select :options="listCompanies" label="name" :reduce="company => company._id" v-model="form.providerCompanyId" id="providerCompany">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.providerCompanyId"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
        </div>
        <div class="form-group">
            <label for="providerCompanyLogo">{{ $t('configuration.workers.providerCompanyLogo') }}</label>
            <div class="input-group">
              <input id="providerCompanyLogo" type="file" class="form-control" ref="providerCompanyLogo" />
              <button class="btn" type="button" @click="uploadProviderCompanyLogo">Upload</button>
            </div>
        </div>
        <div class="form-group">
            <label for="amounts">{{ $t('configuration.workers.amounts') }}</label>
            <textarea id="amounts" type="text" :placeholder="$t('configuration.workers.amounts_placeholder')" class="form-control" v-model="form.amounts" />
        </div>
        <div class="form-group">
          <label for="cardMOP">{{ $t('configuration.workers.cardMOP') }}</label>
          <div class="form-group m-l-lg">
              <label for="mercanetMerchantId">{{ $t('configuration.apis.mercanet.merchantId') }}</label>
              <div>
                <input id="mercanetMerchantId" type="text" :placeholder="$t('configuration.apis.mercanet.merchantId_placeholder')" class="form-control" v-model="form.cardMOP.merchantId" />
              </div>
          </div>
          <div class="form-group m-l-lg">
              <label for="mercanetSecretKey">{{ $t('configuration.apis.mercanet.secretKey') }}</label>
              <div>
                <input id="mercanetSecretKey" type="text" :placeholder="$t('configuration.apis.mercanet.secretKey_placeholder')" class="form-control" v-model="form.cardMOP.secretKey" />
              </div>
          </div>
          <div class="form-group m-l-lg">
              <label for="mercanetKeyVersion">{{ $t('configuration.apis.mercanet.keyVersion') }}</label>
              <div>
                <input id="mercanetKeyVersion" type="text" :placeholder="$t('configuration.apis.mercanet.keyVersion_placeholder')" class="form-control" v-model="form.cardMOP.keyVersion" />
              </div>
          </div>
          <div class="form-group m-l-lg">
              <label for="mercanetBackend">{{ $t('configuration.apis.mercanet.backend') }}</label>
              <div>
                <input id="mercanetBackend" type="text" :placeholder="$t('configuration.apis.mercanet.backend_placeholder')" class="form-control" v-model="form.cardMOP.backend" />
              </div>
          </div>
        </div>
        <div class="form-group">
          <label for="transferMOP">{{ $t('configuration.workers.transferMOP') }}</label>
          <div>
            <p-check id="transferMOP" class="p-switch p-fill" color="success" v-model="form.transferMOP">&nbsp;</p-check>
          </div>
        </div>
        <div class="form-group">
            <label for="registerAdminNotification">{{ $t('configuration.workers.registerAdminNotification') }}</label>
            <div>
              <p-check id="registerAdminNotification" class="p-switch p-fill" color="success" v-model="form.registerAdminNotification">&nbsp;</p-check>
            </div>
        </div>
        <div class="form-group">
            <label for="registerUserNotification">{{ $t('configuration.workers.registerUserNotification') }}</label>
            <div>
              <p-check id="registerUserNotification" class="p-switch p-fill" color="success" v-model="form.registerUserNotification">&nbsp;</p-check>
            </div>
        </div>
        <div class="form-group">
            <label for="validationUserNotification">{{ $t('configuration.workers.validationUserNotification') }}</label>
            <div>
              <p-check id="validationUserNotification" class="p-switch p-fill" color="success" v-model="form.validationUserNotification">&nbsp;</p-check>
            </div>
        </div>
        <div class="form-group">
            <label for="notificationAccount">{{ $t('configuration.workers.notificationAccount') }}</label>
            <v-select :options="listEMailAccounts" v-model="form.notificationAccount" id="notificationAccount">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.notificationAccount"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
        </div>
        

        <button type="submit" class="btn btn-primary block full-width ladda-button" data-style="zoom-in">{{ $t('configuration.form.button') }}</button>
      </form>
    </div>
  </div>
</template>

<style scoped>
  #amounts {
    height: 200px;
    font-size: 12px;
  }

  #mops {
    height: 100px;
    font-size: 12px;
  }
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { enableIbox, disableIbox} from '../../../../theme/inspinia-jquery'; 

@Component({
  components: { }
})
export default class Workers extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly config!: any

  form = {
    providerCompanyId : this.config.providerCompanyId,
    amounts : JSON.stringify(this.config.amounts, null, 2),
    transferMOP : false,
    cardMOP : {
      merchantId : "",
      secretKey : "",
      backend : "",
      keyVersion: ""
    },
    registerAdminNotification : this.config.registerAdminNotification,
    registerUserNotification : this.config.registerUserNotification,
    validationUserNotification : this.config.validationUserNotification,
    notificationAccount : this.config.notificationAccount
  }

  listCompanies:any[] = [];
  listEMailAccounts:string[] = [];

  laddaSubmit:Ladda.LaddaButton|null = null;

  created() {
    this.updateListEmailAccounts();
    this.updateListCompanies();
    this.updateMethodOfPayments();
  }

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=config-workers-form] button.ladda-button' );
    this.laddaSubmit = Ladda.create(button!);
  
    enableIbox(this.$refs.workersConfiguration as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.workersConfiguration as HTMLElement);
  }

  onFormSubmit(evt:Event) {
    evt.preventDefault();

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();

    var input = {
      ...this.form,
      amounts: JSON.parse(this.form.amounts),
    }
    
    api.postAPI('/api/config/eurofiscalis/workers/update', input, options).then((response:any) => {
      if(response.card) {  
        this.form.cardMOP = response.card;
      }
      this.laddaSubmit!.stop();
    });
  }

  updateListEmailAccounts() {
    // We need to get the list of available email accounts for logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/utils/misc/listEmailAccounts', options).then((response:any) => {
      if(response.accounts) {  
        this.listEMailAccounts = response.accounts;
      }
    });
  }

  updateListCompanies() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/config/eurofiscalis/workers/companies', options).then((response:any) => {
      if(response.companies) {  
        this.listCompanies = response.companies;
      }
    });
  }

  updateMethodOfPayments() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/config/eurofiscalis/workers/methodOfPayments', options).then((response:any) => {
      if(response.card) {  
        this.form.cardMOP = response.card;
      }
      if(response.transfer) {
        this.form.transferMOP = true;
      }
    });
  }

  uploadProviderCompanyLogo() {

    var field:HTMLInputElement = this.$refs.providerCompanyLogo as HTMLInputElement;
    var fileList = field.files;
    // handle file changes
    const formData = new FormData();

    if (!fileList || !fileList.length) return;

    // append the files to FormData
    Array
      .from(Array(fileList.length).keys())
      .map(x => {
        formData.append("file_" + x, fileList![x], fileList![x].name);
      });

    // save it
    var options:api.ApiVueOptions =  {
      app: this
    }

    api.postAPIFormData('/api/config/eurofiscalis/workers/providerCompanyLogo', formData, options).then((response:any) => {
      
      if(response.data) {
        console.log(response.data);
      }

    });
  };
}
</script>